import './jquery';
import './common';
import './bootstrap-application';

import 'dropzone/dist/dropzone';
import 'trumbowyg/dist/trumbowyg';
import 'trumbowyg/dist/langs/de.min';
import select2 from 'select2/dist/js/select2.full';

import './utils/bootstrap-init.js';
import './utils/preventMultipleSubmits';
import './utils/confirmation';

// globale Styles
import '../css/global.scss';

select2($);
