import Swal from 'sweetalert2';

document.querySelectorAll('.confirm-post').forEach(entry => {
  entry.addEventListener('click', async (evt) => {
    evt.preventDefault();

    const result = await confirmation(entry);

    if (result.value) {
      await fetch(entry.href, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'token=' + entry.dataset.token
      });

      location.reload();
    }
  });
});

document.querySelectorAll('.confirm-link').forEach(entry => {
  entry.addEventListener('click', async (evt) => {
    evt.preventDefault();

    const result = await confirmation(entry);

    if (result.value) {
      if (entry.getAttribute('data-form')) {
        const form = document.querySelector(entry.getAttribute('data-form'));
        form.submit();
        return;
      }

      if (entry instanceof HTMLButtonElement) {
        const form = entry.closest('form');
        form.submit();
        return;
      }

      location.href = entry.href;
    }
  });
});

document.querySelectorAll('.confirm-submit').forEach(entry => {
  entry.addEventListener('submit', async (evt) => {
    evt.preventDefault();

    const result = await confirmation(entry);

    if (result.value) {
      entry.submit();
    }
  });
});

async function confirmation(entry) {
  const msg = entry.dataset.confirmMsg || 'Sind Sie sicher?';

  return await Swal.fire({
    title: 'Bestätigung',
    text: msg,
    showCancelButton: true,
    cancelButtonText: 'Nein, abbrechen.',
    confirmButtonText: 'Ja, fortfahren.',
  });
}
